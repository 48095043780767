var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom-start",
        width: "100%",
        trigger: "click",
        "popper-class": `el-select-popover ${_vm.popoverClass}`,
        disabled: !_vm.arrowShow,
      },
      on: { show: _vm.showSelect, hide: _vm.hideSelect },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("template", { slot: "reference" }, [
        _c(
          "div",
          {
            staticClass: "title-slot",
            class: [
              _vm.arrowShow ? "arrow-show-popover" : "",
              _vm.iconStatus === 1 ? "title-selected" : "",
            ],
          },
          [
            _vm._v("\n            " + _vm._s(_vm.title) + "\n            "),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.arrowShow,
                  expression: "arrowShow",
                },
              ],
              class:
                _vm.iconStatus === 1
                  ? "el-icon-arrow-up"
                  : "el-icon-arrow-down",
            }),
          ]
        ),
      ]),
      _vm.arrowShow
        ? _c("div", { staticClass: "select-popover" }, [
            _c("div", { staticClass: "select-popover-content" }, [
              _c("div", { staticClass: "select-popover-input" }, [
                _vm.enableSearch
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.keywords,
                          expression: "keywords",
                        },
                      ],
                      staticClass: "select-popover-search",
                      class: { "only-search": _vm.type === "SearchOnly" },
                      attrs: { type: "text", placeholder: _vm.placeholder },
                      domProps: { value: _vm.keywords },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.keywords = $event.target.value
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "select-popover-list" },
                _vm._l(_vm.selectItem, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.keywords ||
                            (_vm.keywords &&
                              item.label &&
                              item.label.indexOf(_vm.keywords) !== -1),
                          expression:
                            "!keywords || (keywords && item.label && item.label.indexOf(keywords) !== -1)",
                        },
                      ],
                      key: index,
                      staticClass: "select-popover-item",
                      class: {
                        "disable-select": _vm.disableSelect && !item.isSelect,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.selectItemHandle($event, item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "text-ellipsis" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.isSelect,
                              expression: "item.isSelect",
                            },
                          ],
                          attrs: { hidden: "", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(item.isSelect)
                              ? _vm._i(item.isSelect, null) > -1
                              : item.isSelect,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = item.isSelect,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      item,
                                      "isSelect",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      item,
                                      "isSelect",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(item, "isSelect", $$c)
                              }
                            },
                          },
                        }),
                        _c("span", {
                          staticClass: "select-popover-item-checkbox",
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "select-popover-item-content text-ellipsis",
                            attrs: { title: item.label },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.label) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm.enableCount
                          ? _c(
                              "span",
                              { staticClass: "select-popover-item-count" },
                              [
                                _vm._v(
                                  "\n                            (" +
                                    _vm._s(item.count) +
                                    ")\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm.enableBtn
              ? _c(
                  "div",
                  { staticClass: "btn-container" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn-reset",
                        attrs: { size: "mini" },
                        on: { click: _vm.resetSelect },
                      },
                      [_vm._v("\n                重置\n            ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn-confirm",
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.confirm },
                      },
                      [_vm._v("\n                确定\n            ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }